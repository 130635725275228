@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: "Flexa";
  src: url("fonts/GT-Flexa-Regular-Trial.woff2") format('woff');
}

@font-face {
  font-family: "Flexa";
  src: url("fonts/GT-Flexa-Bold-Trial.woff2") format('woff');
  font-weight: bold;
}

@font-face {
  font-family: "Flexa";
  src: url("fonts/GT-Flexa-Thin-Trial.woff2") format('woff');
  font-weight: lighter;
}

:root {
  --primaryColor: #f9f9f9;
  --primaryWhite: #ffffff;
  --secColor: #E1000F;
  --terColor: #bfcfbe;
  --black: #000000;
  --appBg: #f9f9f9;
}

.MuiTypography-root {
  font-family: 'Flexa', 'Segoe UI', Helvetica, sans-serif !important;
}

body {
  font-family: 'Flexa', 'Segoe UI', Helvetica, sans-serif !important;
  color: var(--black) !important;
}

.App {
  background-color: var(--appBg);
  font-family: 'Flexa', 'Segoe UI', Helvetica, sans-serif !important;
  color: var(--black) !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}

a {
  color: var(--appBg) !important;
}

.a2 {
  color: var(--secColor) !important;
}

@keyframes growShrink {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}